import LocalizedStrings from 'react-localization';

const Strings = new LocalizedStrings({
  en: {
    DEBUG_LOREMIPSUM: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id tempor mauris. Aenean porttitor tristique pharetra. Praesent dignissim rutrum aliquam. Vivamus sed imperdiet orci, ac varius ipsum. Vestibulum est elit, laoreet nec ultrices et, ornare in eros. Nullam tempus, libero nec vulputate condimentum, mauris quam vestibulum nunc, id pharetra lorem turpis malesuada lacus. Proin sagittis ullamcorper sapien, in aliquam velit lacinia a.\nMauris egestas, dolor aliquam suscipit suscipit, nibh magna dapibus arcu, vel lobortis nibh ante ultrices libero. Mauris scelerisque condimentum gravida. Vivamus ante tortor, congue id mauris id, dignissim eleifend risus. Integer sed auctor sapien. Nam aliquet nibh id viverra placerat. Nullam dolor leo, ullamcorper vitae ex lobortis, lacinia commodo nibh. Donec aliquam suscipit tempor. Etiam sed dignissim turpis. Nulla et augue lacinia, pharetra dolor et, facilisis quam.\nAenean ac nulla eu est vulputate rhoncus. Morbi molestie ut sem sit amet tempus. Nulla orci leo, porta ac massa vel, pulvinar blandit magna. In sed velit tincidunt, eleifend enim ut, viverra lectus. Pellentesque hendrerit augue et arcu ultrices placerat. Nulla metus magna, rhoncus ut maximus id, efficitur bibendum tellus. Pellentesque ullamcorper aliquam suscipit. Proin sed convallis ex. Donec non lectus dolor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam iaculis mollis lacus ut mattis. Nunc malesuada urna id risus maximus, a fermentum metus congue. Etiam ornare purus vel tortor aliquam, quis tincidunt ligula efficitur. Duis mollis tristique dui, nec accumsan nibh ultrices quis. Phasellus faucibus tortor vitae est varius cursus. Sed pharetra non odio non ornare. ', // TODO: Remove
    home: 'Home',
    gallery: 'Gallery',
    guitars: 'Guitars',
    about: 'About',
    contact: 'Contact',
    instagram: 'Instagram',
    facebook: 'Facebook',
    madeBy: 'Website created by ',
    copyright: 'Copyright © Zoran Kuvač',
    zoranKuvac: 'Zoran Kuvač',
    homePageDescription: 'Classical Guitars',
    moreInfo: 'More Info',
    address1: 'Kozarska 58',
    address2: '78000 Banja Luka',
    address3: 'Republika Srpska',
    address4: 'Bosnia & Herzegovina',
    phone: 'Phone:',
    mobilePhone: 'Mobile Phone:',
    aboutMe: 'About Me',
    wood: 'It All Begins with the Wood',
    whoAmI: 'My name is Zoran Kuvač.\nI\'ve always been infatuated with the sound of a classical guitar. My love for the classical guitar first took me to a school of music where I learned to play. But this wasn\'t enough - I wanted to create. I dreamed of having a guitar I made myself.\nThe idea of crafting guitars came to be when, as a child, I saw a hand-crafted guitar and realized that I didn\'t need a factory to make guitars. The second significant event was the repair of an almost completely destroyed guitar, which showed me that I have some talent for craftsmanship. However, I knew that talent was not enough. The third step was my departure for Serbia, a result of the war in Bosnia and Herzegovina in 1993. Since, in Serbia, I did not have the opportunity to work, I decided to learn something - of course that being guitar luthiery. I could not find a guitar luthier, but I did one of the best tamburica luthiers, Duško Rajković, who used to work in Sremska Mitrovica. He gifted me with the knowledge of tamburica luthiery.\nCircumstances led me to Banjaluka, where I found a home for myself and dedicated myself to the manufacturing of classical guitars. In 1998, I opened my workshop. Now, after decades of experience, I am certain that the guitars I make, from only the finest materials, are ones which create an incomparable and special sound.',
    woodQuality: 'In order to make a beautifully sounding guitar, its wood must be of the highest quality. Because of this, my wood is hand-picked to be exclusively the finest.\nBosnia and Herzegovina is famous for its lush forests. Having more than half of its land in forests, there is access to exquisite wood which lends itself to the manufacturing of guitars.\nI am in luck to have the access to Bosnia and Herzegovina’s famous wood, namely our spruce and maple. Famous for their tonal and aesthetic qualities, these types of wood are the starting point for my guitars. Every piece of wood is inspected to make sure it is only of the highest quality and will produce the most pleasant of timbres.',
    luthiery: 'Making the Guitar Sing',
    luthieryDesc: 'Having made sure that the woods I will use for a guitar are the best available, I start designing my guitar and planning for how it will look and sound. I usually base my designs on Friederich, Torres, Dominique and Romanillos Guitars. With these beautiful bases, I meticulously design the details - from the rosette to the headstock.\nI make every element of the guitar by hand, except the tuning pegs, to ensure that the guitar will be as beautiful and natural sounding as possible. I design the neck so that it fits perfectly in the hands of an experienced guitarist, as well as players who have recently started playing. I craft the body prioritizing the beauty and quality of the timbre, but I make sure that the guitar makes a powerful sound.\nEvery step in the manufacturing of a guitar is done patiently and carefully. I make sure to take my time in making every piece of the guitar, from the initial cut to the polishing of the guitars. This guarantees that my guitars very close to perfection.',
    videoCourtesy: 'Video Courtesy of ',
    usPleaseSee: 'Customers in the US, please visit GSI\'s website for instructions on how to order our guitars.',
    lanskyTitle: 'The Cedar 2015 Model',
    lanskyDescription: 'This beautiful Canadian Cedar top model from 2015 produces a rich bass as well as clear and refined mids and highs.',
    ponceTitle: 'The Bosnian Spruce 2018 Model',
    ponceDescription: 'The Bosnian Spruce top model from 2018 is gentle, but clear and vocal. Its beautiful Bosnian Spruce top grants it an unparalleled visual beauty.',
    piazzollaTitle: 'The 2018 Spruce model',
    piazzollaDescription: 'This fantastic spruce top guitar produces clear mids showing off the sound of the guitar in its true glory.'
  },
  sr: {
    home: 'Početna',
    gallery: 'Galerija',
    guitars: 'Gitare',
    about: 'O Meni',
    contact: 'Kontakt',
    instagram: 'Instagram',
    facebook: 'Facebook',
    madeBy: 'Vebsajt napravio ',
    copyright: 'Sva Autorska Prava Zaštićena © Zoran Kuvač',
    zoranKuvac: 'Zoran Kuvač',
    homePageDescription: 'Klasične Gitare',
    moreInfo: 'Više Informacija',
    address1: 'Kozarska 58',
    address2: '78000 Banja Luka',
    address3: 'Republika Srpska',
    address4: 'Bosna i Hercegovina',
    phone: 'Telefon:',
    mobilePhone: 'Mobilni Telefon:',
    aboutMe: 'O Meni',
    wood: 'Sve počinje sa drvetom',
    whoAmI: 'Ja sam Zoran Kuvač, majstor klasičnih gitara.\nOduvijek sam volio zvuk klasične gitare. Ta ljubav me je odvela prvo u muzičku školu gdje sam naučio da sviram, ali to mi nije bilo dovoljno - želio sam da stvaram. Maštao sam da imam svoju gitaru koju sam lično izradio.\nIdeja o izradi gitare se rodila kad sam na jednom sajmu prvi put vidio ručno izrađenu gitaru i kada sam shvatio da nije potrebno imati fabriku da bi se napravila. Drugi značajan događaj bio je popravka jedne znatno oštećene, gotovo uništene gitare, koji mi je pokazao da imam talenta za obradu drveta. Ali znao sam da talenat nije dovoljan. Treći korak je moj odlazak u Srbiju, koji je posljedica ratnih dešavanja u Bosni i Hercegovini ‘93. godine. Kako u Srbiji nisam imao priliku da radim, odlučio sam nešto da naučim, a naravno to što sam htio da naučim je bila izrada gitara. Nisam mogao da pronađem majstora gitara, ali sam pronašao jednog od najboljih izrađivača tamburica, Duška Rajkovića, koji je radio u Sremskoj Mitrovici. On me je podario znanjem izrade tamburica.\nSplet okolnosti me je doveo u Banjaluku, gdje sam se nastanio i odlučio da se posvetim izradi klasičnih gitara. Godine 1998. otvorio sam svoju radionicu. Sada, nakon dugogodišnjeg iskustva, sam siguran da gitare koje proizvodim od isključivo najboljih materijala stvaraju neuporediv i poseban zvuk.',
    woodQuality: 'Da bi se napravila gitara koja predivno zvuči, drvo od kojeg je napravljena mora biti najbolje kvalitete. Imajući ovo u vidu, moje drvo je birano da isključivo bude najfinije.\nBosna i Hercegovina je poznata po svojim gustim šumama. Uz obzir da je pola zemlje u šumama, postoji pristup ekskvizitnom drvetu koje se nudi proizvodnji gitara.\nImam sreću da imam pristup poznatim drvima Bosne i Hercegovine, naime smrči i javoru. Kako je ovo drvo odlično po svojim tonski i aestetičnim vrijednostima, ovakvo drvo je početna tačka mojih gitara. Svaki komad drveta pregledam da se uvjerim da je isključivo najbolje kvalitete i da proizvodi najljepšu boju zvuka.',
    luthiery: 'Izrada gitare',
    luthieryDesc: 'Kada sam ubjeđen da je drvo koje ću koristiti za gitaru najbolje moguće, počinjem da dizajniram gitaru i planiram kako će izgledati i zvučati. Inače baziram svoje dizajne na Friederich, Torres, Dominique i Romanillos gitare. Sa ovim prelijepim bazama, pažljivo dizajniram detalje - od rozete do glave gitare.\nSvaki element gitare radim ručno, osim štimera, da budem siguran da će gitara odlično kako izgledati, tako i zvučati. Vrat pravim tako da on savršeno liježe u ruke iskusnog gitariste, ali i gitarista koji su tek počeli da sviraju. Tijelo konstruišen prioritizirajući ljepotu i kvalitet tona, ali se i pobrinem da gitara bude dovoljno zvučna.\nSvaki korak u izradi gitare radim pažljivo i strpljivo. Ne žurim u izradi svakog malog detalja gitare - od prvog reza do poliranja gitare. Ovim garantujem da su moje gitare blizu savršenstva.',
    videoCourtesy: 'Video dostupan zbog ljubaznosti ',
    usPleaseSee: '',
    lanskyTitle: 'Model iz 2015. od kanadskog kedra',
    lanskyDescription: 'Ovaj prelijepi model kanadskog kedra proizvodi bogate basove i čiste i prefinjene niske i visoke tonove.',
    ponceTitle: 'Model iz 2018 od bosanske smreke',
    ponceDescription: 'Ovaj model sa topom od bosanske smreke iz 2018 je nježan, ali i jasan. Predivni top bosanske smreke mu daje neuporedivu estetsku ljepotu.',
    piazzollaTitle: 'Model iz 2018. od šmreke',
    piazzollaDescription: 'Ova fantastična gitara sa topom od šmreke proizvodi čiste srednje frekvencije koje pokazuju gitaru u njenoj punoj ljepoti.'
  },
  bs: {
    home: 'Početna',
    gallery: 'Galerija',
    guitars: 'Gitare',
    about: 'O Meni',
    contact: 'Kontakt',
    instagram: 'Instagram',
    facebook: 'Facebook',
    madeBy: 'Vebsajt napravio ',
    copyright: 'Sva Autorska Prava Zaštićena © Zoran Kuvač',
    zoranKuvac: 'Zoran Kuvač',
    homePageDescription: 'Klasične Gitare',
    moreInfo: 'Više Informacija',
    address1: 'Kozarska 58',
    address2: '78000 Banja Luka',
    address3: 'Republika Srpska',
    address4: 'Bosna i Hercegovina',
    phone: 'Telefon:',
    mobilePhone: 'Mobilni Telefon:',
    aboutMe: 'O Meni',
    wood: 'Sve počinje sa drvetom',
    whoAmI: 'Ja sam Zoran Kuvač, majstor klasičnih gitara.\nOduvijek sam volio zvuk klasične gitare. Ta ljubav me je odvela prvo u muzičku školu gdje sam naučio da sviram, ali to mi nije bilo dovoljno - želio sam da stvaram. Maštao sam da imam svoju gitaru koju sam lično izradio.\nIdeja o izradi gitare se rodila kad sam na jednom sajmu prvi put vidio ručno izrađenu gitaru i kada sam shvatio da nije potrebno imati fabriku da bi se napravila. Drugi značajan događaj bio je popravka jedne znatno oštećene, gotovo uništene gitare, koji mi je pokazao da imam talenta za obradu drveta. Ali znao sam da talenat nije dovoljan. Treći korak je moj odlazak u Srbiju, koji je posljedica ratnih dešavanja u Bosni i Hercegovini ‘93. godine. Kako u Srbiji nisam imao priliku da radim, odlučio sam nešto da naučim, a naravno to što sam htio da naučim je bila izrada gitara. Nisam mogao da pronađem majstora gitara, ali sam pronašao jednog od najboljih izrađivača tamburica, Duška Rajkovića, koji je radio u Sremskoj Mitrovici. On me je podario znanjem izrade tamburica.\nSplet okolnosti me je doveo u Banjaluku, gdje sam se nastanio i odlučio da se posvetim izradi klasičnih gitara. Godine 1998. otvorio sam svoju radionicu. Sada, nakon dugogodišnjeg iskustva, sam siguran da gitare koje proizvodim od isključivo najboljih materijala stvaraju neuporediv i poseban zvuk.',
    woodQuality: 'Da bi se napravila gitara koja predivno zvuči, drvo od kojeg je napravljena mora biti najbolje kvalitete. Imajući ovo u vidu, moje drvo je birano da isključivo bude najfinije.\nBosna i Hercegovina je poznata po svojim gustim šumama. Uz obzir da je pola zemlje u šumama, postoji pristup ekskvizitnom drvetu koje se nudi proizvodnji gitara.\nImam sreću da imam pristup poznatim drvima Bosne i Hercegovine, naime smrči i javoru. Kako je ovo drvo odlično po svojim tonski i aestetičnim vrijednostima, ovakvo drvo je početna tačka mojih gitara. Svaki komad drveta pregledam da se uvjerim da je isključivo najbolje kvalitete i da proizvodi najljepšu boju zvuka.',
    luthiery: 'Izrada gitare',
    luthieryDesc: 'Kada sam ubjeđen da je drvo koje ću koristiti za gitaru najbolje moguće, počinjem da dizajniram gitaru i planiram kako će izgledati i zvučati. Inače baziram svoje dizajne na Friederich, Torres, Dominique i Romanillos gitare. Sa ovim prelijepim bazama, pažljivo dizajniram detalje - od rozete do glave gitare.\nSvaki element gitare radim ručno, osim štimera, da budem siguran da će gitara odlično kako izgledati, tako i zvučati. Vrat pravim tako da on savršeno liježe u ruke iskusnog gitariste, ali i gitarista koji su tek počeli da sviraju. Tijelo konstruišen prioritizirajući ljepotu i kvalitet tona, ali se i pobrinem da gitara bude dovoljno zvučna.\nSvaki korak u izradi gitare radim pažljivo i strpljivo. Ne žurim u izradi svakog malog detalja gitare - od prvog reza do poliranja gitare. Ovim garantujem da su moje gitare blizu savršenstva.',
    videoCourtesy: 'Video dostupan zbog ljubaznosti ',
    usPleaseSee: '',
    lanskyTitle: 'Test 123',
    lanskyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id tempor mauris.',
    ponceTitle: 'Test 123',
    ponceDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id tempor mauris.',
    piazzollaTitle: 'Test 123',
    piazzollaDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id tempor mauris.'
  },
  hr: {
    home: 'Početna',
    gallery: 'Galerija',
    guitars: 'Gitare',
    about: 'O Meni',
    contact: 'Kontakt',
    instagram: 'Instagram',
    facebook: 'Facebook',
    madeBy: 'Vebsajt napravio ',
    copyright: 'Sva Autorska Prava Zaštićena © Zoran Kuvač',
    zoranKuvac: 'Zoran Kuvač',
    homePageDescription: 'Klasične Gitare',
    moreInfo: 'Više Informacija',
    address1: 'Kozarska 58',
    address2: '78000 Banja Luka',
    address3: 'Republika Srpska',
    address4: 'Bosna i Hercegovina',
    phone: 'Telefon:',
    mobilePhone: 'Mobilni Telefon:',
    aboutMe: 'O Meni',
    wood: 'Sve počinje sa drvetom',
    whoAmI: 'Ja sam Zoran Kuvač, majstor klasičnih gitara.\nOduvijek sam volio zvuk klasične gitare. Ta ljubav me je odvela prvo u muzičku školu gdje sam naučio da sviram, ali to mi nije bilo dovoljno - želio sam da stvaram. Maštao sam da imam svoju gitaru koju sam lično izradio.\nIdeja o izradi gitare se rodila kad sam na jednom sajmu prvi put vidio ručno izrađenu gitaru i kada sam shvatio da nije potrebno imati fabriku da bi se napravila. Drugi značajan događaj bio je popravka jedne znatno oštećene, gotovo uništene gitare, koji mi je pokazao da imam talenta za obradu drveta. Ali znao sam da talenat nije dovoljan. Treći korak je moj odlazak u Srbiju, koji je posljedica ratnih dešavanja u Bosni i Hercegovini ‘93. godine. Kako u Srbiji nisam imao priliku da radim, odlučio sam nešto da naučim, a naravno to što sam htio da naučim je bila izrada gitara. Nisam mogao da pronađem majstora gitara, ali sam pronašao jednog od najboljih izrađivača tamburica, Duška Rajkovića, koji je radio u Sremskoj Mitrovici. On me je podario znanjem izrade tamburica.\nSplet okolnosti me je doveo u Banjaluku, gdje sam se nastanio i odlučio da se posvetim izradi klasičnih gitara. Godine 1998. otvorio sam svoju radionicu. Sada, nakon dugogodišnjeg iskustva, sam siguran da gitare koje proizvodim od isključivo najboljih materijala stvaraju neuporediv i poseban zvuk.',
    woodQuality: 'Da bi se napravila gitara koja predivno zvuči, drvo od kojeg je napravljena mora biti najbolje kvalitete. Imajući ovo u vidu, moje drvo je birano da isključivo bude najfinije.\nBosna i Hercegovina je poznata po svojim gustim šumama. Uz obzir da je pola zemlje u šumama, postoji pristup ekskvizitnom drvetu koje se nudi proizvodnji gitara.\nImam sreću da imam pristup poznatim drvima Bosne i Hercegovine, naime smrči i javoru. Kako je ovo drvo odlično po svojim tonski i aestetičnim vrijednostima, ovakvo drvo je početna tačka mojih gitara. Svaki komad drveta pregledam da se uvjerim da je isključivo najbolje kvalitete i da proizvodi najljepšu boju zvuka.',
    luthiery: 'Izrada gitare',
    luthieryDesc: 'Kada sam ubjeđen da je drvo koje ću koristiti za gitaru najbolje moguće, počinjem da dizajniram gitaru i planiram kako će izgledati i zvučati. Inače baziram svoje dizajne na Friederich, Torres, Dominique i Romanillos gitare. Sa ovim prelijepim bazama, pažljivo dizajniram detalje - od rozete do glave gitare.\nSvaki element gitare radim ručno, osim štimera, da budem siguran da će gitara odlično kako izgledati, tako i zvučati. Vrat pravim tako da on savršeno liježe u ruke iskusnog gitariste, ali i gitarista koji su tek počeli da sviraju. Tijelo konstruišen prioritizirajući ljepotu i kvalitet tona, ali se i pobrinem da gitara bude dovoljno zvučna.\nSvaki korak u izradi gitare radim pažljivo i strpljivo. Ne žurim u izradi svakog malog detalja gitare - od prvog reza do poliranja gitare. Ovim garantujem da su moje gitare blizu savršenstva.',
    videoCourtesy: 'Video dostupan zbog ljubaznosti ',
    usPleaseSee: '',
    lanskyTitle: 'Test 123',
    lanskyDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id tempor mauris.',
    ponceTitle: 'Test 123',
    ponceDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id tempor mauris.',
    piazzollaTitle: 'Test 123',
    piazzollaDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id tempor mauris.'
  }
});

export default Strings;
