export const XL_MIN_W = 1281;

export const LG_MIN_W = 1025;
export const LG_MAX_W = 1280;

export const MED_MIN_W = 768;
export const MED_MAX_W = 1024;

export const SM_MIN_W = 481;
export const SM_MAX_W = 767;

export const XS_MIN_W = 320;
export const XS_MAX_W = 480;