import React from 'react';
import './ContactPage.scss';
import MediaQuery from 'react-responsive';
import { MED_MIN_W } from './../../common/resolutions';
import Strings from './../../common/strings';
import Footer from './../../components/Footer/Footer';

function ContactPage() {
  return(
    <div className="cover-wrapper workshop-background">
      <div className="cover-container padding-lr-5">
        <MediaQuery minWidth={MED_MIN_W}>
          <div className="split-view">
            <div className="split-view-l split-view-stretched">
              <iframe title="Map" className="full-width full-height" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBhPB_uLZ7KpjX4ZdiUTy1eArKXhRutI3Q&q=Kozarska+58&zoom=15" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </div>
            <div className="split-view-r breakout rosette-background centered-content">
              <div>
                <div className="address">
                  <span>{Strings.address1}</span>
                  <span>{Strings.address2}</span>
                  <span>{Strings.address3}</span>
                  <span>{Strings.address4}</span>
                </div>
                <div className="contact-links">
                  <div><span>{Strings.phone} </span><a href="tel:+38751216906">+387 51 216 906</a></div>
                  <div><span>{Strings.mobilePhone} </span><a href="tel:+38765832495">+387 65 832 495</a></div>
                  <a href="mailto:kuvaczoran@gmail.com">kuvaczoran@gmail.com</a>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={MED_MIN_W}>
          <div className="s-contact-content">
            <div className="rosette-background full-width one-to-one-ratio">
              <div className="centered-content one-to-one-ratio-inner">
                <div className="address">
                  <span>{Strings.address1}</span>
                  <span>{Strings.address2}</span>
                  <span>{Strings.address3}</span>
                  <span>{Strings.address4}</span>
                </div>
                <div className="contact-links">
                  <div><span>{Strings.phone} </span><a href="tel:+38751216906">+387 51 216 906</a></div>
                  <div><span>{Strings.mobilePhone} </span><a href="tel:+38765832495">+387 65 832 495</a></div>
                  <a href="mailto:kuvaczoran@gmail.com">kuvaczoran@gmail.com</a>
                </div>
              </div>
            </div>
            <div className="s-contact-map full-width">
              <iframe title="Map" className="full-width full-height" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBhPB_uLZ7KpjX4ZdiUTy1eArKXhRutI3Q&q=Kozarska+58&zoom=15" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </div>
          </div>
        </MediaQuery>
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;