import React from 'react';
import Dots from '../Dots/Dots';
import './CardedLayout.scss'

class Card extends React.Component {
  render() {
    return(
      <div className="carded-layout-page">
        <div className="carded-layout-main-element">
          {this.props.element.main}
        </div>
        <div className="carded-layout-desc-element">
          {this.props.element.desc}
        </div>
      </div>
    )
  }
}

class CardedLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pagesNum: this.props.elements.length,
      activePage: 0
    }

    this.elements = Array.from(Array(this.state.pagesNum).keys()).map((index) =>
      <Card key={index} element={this.props.elements[index]} />
    );

    this.changePage = this.changePage.bind(this);
  }

  changePage(e) {
    console.log(e)
    this.setState({
      activePage: e
    });
  }

  render() {
    return(
      <div className="carded-layout-wrapper">
        {this.elements[this.state.activePage]}

        <Dots onClick={this.changePage} num={this.props.elements.length} className="carded-layout-dots" length={this.state.pages} active={this.state.activePage} />
      </div>
    );
  }
}

CardedLayout.defaultProps = {
  elements: []
}

export default CardedLayout;