import React from 'react';
import './Dots.scss';

class Dot extends React.Component {
  render() {
    if (this.props.active) {
      return(
        <div onClick={this.props.onClick} className="dots-circle-active" style={{marginBottom: this.props.margin, borderWidth: this.props.outline, width: this.props.size, height: this.props.size}} />
      );
    } else {
      return(
        <div onClick={this.props.onClick} className="dots-circle" style={{marginBottom: this.props.margin, borderWidth: this.props.outline, width: this.props.size, height: this.props.size}} />
      );
    }
  }
}

class Dots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: Array.from(Array(this.props.num).keys()).map((index) => {
        if (index === this.props.activeNum) {
          return(<Dot active key={index.toString()} size={this.props.size} onClick={() => this.clicked(index)} outline={this.props.outline} margin={this.props.margin} />);
        } else {
          return(<Dot key={index.toString()} size={this.props.size} onClick={() => this.clicked(index)} outline={this.props.outline} margin={this.props.margin} />);
        }
      })
    }

    this.clicked = this.clicked.bind(this);
  }

  clicked(e) {
    this.setState({
      elements: Array.from(Array(this.props.num).keys()).map((index) => {
        if (index === e) {
          return(<Dot active key={index.toString()} size={this.props.size} onClick={() => this.clicked(index)} outline={this.props.outline} margin={this.props.margin} />);
        } else {
          return(<Dot key={index.toString()} size={this.props.size} onClick={() => this.clicked(index)} outline={this.props.outline} margin={this.props.margin} />);
        }
      })
    });

    this.props.onClick(e);
  }

  render() {
    return(
      <ul className={this.props.className} style={this.props.style}>{this.state.elements}</ul>
    );
  }
}

Dots.defaultProps = {
  size: '10px',
  outline: '1.2px',
  num: 0,
  margin: '0.2em',
  activeNum: 0,
  onClick: (e) => {}
}

export default Dots;