import React from 'react';
import IndexPage from './../../routes/IndexPage/IndexPage';
import GalleryPage from './../../routes/GalleryPage/GalleryPage';
import ContactPage from './../../routes/ContactPage/ContactPage';
import GuitarsPage from './../../routes/GuitarsPage/GuitarsPage';
import AboutPage from './../../routes/AboutPage/AboutPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './../Navbar/Navbar';

function App() {
  return (
    <Router>
      <Navbar />
      <Route exact path="/" component={IndexPage} />
      <Route path="/gallery" component={GalleryPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/guitars" component={GuitarsPage} />
    </Router>
  );
}

export default App;
