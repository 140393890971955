import React from 'react';
import './IndexPage.scss';
import { Link } from 'react-router-dom';
import Strings from './../../common/strings';
import Footer from './../../components/Footer/Footer';

function IndexPage() {
  return(
    <div className="cover-wrapper front-image">
      <div className="cover-container">
        <h1>{Strings.zoranKuvac}</h1>
        <div className="kuvac-description">
          <h2>{Strings.homePageDescription}</h2>
        </div>
        <div className="more-info-button">
          <h3><Link className="white-link" to="/guitars">{Strings.moreInfo}</Link></h3>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default IndexPage;