import React from 'react';
import Strings from './../../common/strings';
import './CardContent.scss';

class CardContent extends React.Component {
  render() {
    return(
      <div className="card-content">
        <h4 className="card-content-title">{this.props.title}</h4>
        <p className="card-content-description">{this.props.description}</p>
        <small className="card-content-footer">
        	{Strings.videoCourtesy}<a href="https://www.guitarsalon.com/">GSI</a>.<br />
        	{Strings.usPleaseSee}
        </small>
      </div>
    );
  }
}

export default CardContent;