import React from 'react';
import './GuitarsPage.scss';
import MediaQuery from 'react-responsive';
import { MED_MIN_W } from './../../common/resolutions';
import Strings from './../../common/strings';
import Footer from './../../components/Footer/Footer';
import CardedLayout from './../../components/CardedLayout/CardedLayout';
import CardContent from './../../components/CardContent/CardContent';
import YouTube from 'react-youtube';
import ResponsiveEmbed from 'react-responsive-embed';

class GuitarsPage extends React.Component {
  constructor(props) {
    super(props);

    this.videoPlayerOpts = {
      playerVars: {
        autoplay: 1,
        color: 'white',
        controls: 0,
        fs: 0,
        modestbranding: 1,
        showinfo: 0,
        playsinline: 1
      }
    }

    this.videos = [
      {
        main: <YouTube videoId="SKueMVQRQ7k" opts={this.videoPlayerOpts} />,
        desc: <CardContent title={Strings.lanskyTitle} description={Strings.lanskyDescription} />
      },
      {
        main: <YouTube videoId="upNcnMlQzkI" opts={this.videoPlayerOpts} />,
        desc: <CardContent title={Strings.ponceTitle} description={Strings.ponceDescription} />
      },
      {
        main: <YouTube videoId="faHaXk6R3Ns" opts={this.videoPlayerOpts} />,
        desc: <CardContent title={Strings.piazzollaTitle} description={Strings.piazzollaDescription} />
      }
    ]
  }

  render() {
    return(
      <div className="cover-wrapper background-guitar">
        <div className="cover-container">
          <MediaQuery minWidth={MED_MIN_W}>
            <CardedLayout elements={this.videos} />
          </MediaQuery>
          <MediaQuery maxWidth={MED_MIN_W}>
            <div className="guitars-mobile-content">
              <ResponsiveEmbed src='https://www.youtube.com/embed/SKueMVQRQ7k' allowFullScreen />
              <div className="guitars-description">
                <h4>{Strings.lanskyTitle}</h4>
                <p>{Strings.lanskyDescription}</p>
                <small>{Strings.videoCourtesy}<a href="https://www.guitarsalon.com/">GSI</a>.</small>
              </div>
              <ResponsiveEmbed src='https://www.youtube.com/embed/upNcnMlQzkI' allowFullScreen />
              <div className="guitars-description">
                <h4>{Strings.ponceTitle}</h4>
                <p>{Strings.ponceDescription}</p>
                <small>{Strings.videoCourtesy}<a href="https://www.guitarsalon.com/">GSI</a>.</small>
              </div>
              <ResponsiveEmbed src='https://www.youtube.com/embed/faHaXk6R3Ns' allowFullScreen />
              <div className="guitars-description">
                <h4>{Strings.piazzollaTitle}</h4>
                <p>{Strings.piazzollaDescription}</p>
                <small>{Strings.videoCourtesy}<a href="https://www.guitarsalon.com/">GSI</a>.</small>
              </div>
            </div>
          </MediaQuery>
        </div>
        <Footer />
      </div>
    );
  }
}

export default GuitarsPage;