import React from 'react';
import './AboutPage.scss';
import MediaQuery from 'react-responsive';
import { MED_MIN_W } from './../../common/resolutions';
import Strings from './../../common/strings';
import Footer from './../../components/Footer/Footer';
import Luthier from './../../assets/images/Luthier.jpg';
import Worksmanship from './../../assets/images/Worksmanship.jpg';

function AboutPage() {
  return(
    <div>
      <div className="parted-container">
        <div className="parted-part-1">
          <MediaQuery minWidth={MED_MIN_W}>
            <div className="split-view">
              <div className="split-view-l split-view-stretched" >
                <img className="cover" src={Luthier} alt={Strings.zoranKuvac} />
              </div>
              <div className="split-view-r half-width padding-all-2 maple-background">
                <h3 className="text-dark">{Strings.aboutMe}</h3>
                <p className="text-dark text-justified">{Strings.whoAmI}</p>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={MED_MIN_W}>
            <div className="maple-background padding-all-2">
              <h3 className="text-dark">{Strings.aboutMe}</h3>
              <p className="text-justified text-dark">{Strings.whoAmI}</p>
            </div>
            <div>
              <img className="cover" src={Luthier} alt={Strings.zoranKuvac} />
            </div>
          </MediaQuery>
        </div>
        <div className="parted-part-2 padding-all-2 rosewood-background">
          <h3 className="text-light">{Strings.wood}</h3>
          <p className="text-justified text-light">{Strings.woodQuality}</p>
        </div>
        <div className="parted-part-3">
          <MediaQuery minWidth={MED_MIN_W}>
            <div className="split-view">
              <div className="split-view-l half-width padding-all-2 maple-background" >
                <h3 className="text-dark">{Strings.luthiery}</h3>
                <p className="text-justified text-dark">{Strings.luthieryDesc}</p>
              </div>
              <div className="split-view-r split-view-stretched">
                <img className="cover" src={Worksmanship} alt={Strings.zoranKuvac} />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={MED_MIN_W}>
            <div className="maple-background padding-all-2">
              <h3 className="text-dark">{Strings.aboutMe}</h3>
              <p className="text-justified text-dark">{Strings.DEBUG_LOREMIPSUM}</p>
            </div>
            <div>
              <img className="cover" src={Worksmanship} alt={Strings.zoranKuvac} />
            </div>
          </MediaQuery>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;