import React from 'react';
import {withRouter} from 'react-router-dom';
import './Footer.scss';
import MediaQuery from 'react-responsive';
import { XS_MIN_W } from './../../common/resolutions';
import Strings from './../../common/strings';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

class Footer extends React.Component {
  render() {
    if (this.props.location.pathname === '/') {
      // Light themed footer
      return(
        <div className="footer-light">
          <MediaQuery minDeviceWidth={XS_MIN_W}>
            <div className="footer-container">

              <div className="social-links">
                <a href="https://www.facebook.com" className="social-link-light">
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </a>
                <a href="https://www.instagram.com" className="social-link-light">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </div>

              <div className="footer-light-text">
                {Strings.copyright}
              </div>
              <div>
                <small className="footer-light-text">
                  {Strings.madeBy}<a className="white-link" href="https://www.markovejnovic.com">Marko Vejnovic</a>.
                </small>
              </div>
            </div>
          </MediaQuery>
        </div>
      );
    } else {
      // Standard footer
      return(
        <div className="footer wood-background">
          <MediaQuery minDeviceWidth={XS_MIN_W}>
            <div className="footer-container">

              <div className="social-links">
                <a href="https://www.facebook.com" className="social-link"><FontAwesomeIcon icon={faFacebook} size="lg" /></a>
                <a href="https://www.instagram.com" className="social-link"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
              </div>

              <div>
                {Strings.copyright}
              </div>
              <div>
                <small>{Strings.madeBy}<a href="https://www.markovejnovic.com">Marko Vejnovic</a>.</small>
              </div>
            </div>
          </MediaQuery>
        </div>
      );
    }
  }
}

export default withRouter(Footer);