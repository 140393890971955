import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import MediaQuery from 'react-responsive';
import { SM_MIN_W } from './../../common/resolutions';
import Strings from './../../common/strings';
import logo from './../../assets/images/logo.png';
require('hamburgers/dist/hamburgers.min.css')

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuHidden: true,
      hamburgerClass: 'hamburger--squeeze',
      navbarClass: 's-navbar-container s-navbar-hidden'
    };
  }

  buttonClicked() {
    if (this.state.menuHidden) {
      this.setState({
        menuHidden: false,
        hamburgerClass: 'hamburger--squeeze is-active',
        navbarClass: 's-navbar-container'
      });
    } else {
      this.setState({
        menuHidden: true,
        hamburgerClass: 'hamburger--squeeze',
        navbarClass: 's-navbar-container s-navbar-hidden'
      });
    }
  }

  render() {
    return(
        <div className="navbar wood-background">
          <MediaQuery minWidth={SM_MIN_W}>
            <nav className="b-navbar-container">
              <div className="b-navbar-left">
                <Link className="navbar-link" to="/gallery">{Strings.gallery}</Link>
                <Link className="navbar-link" to="/guitars">{Strings.guitars}</Link>
              </div>

              <Link className="logo-wrapper" to="/">
                <img className="logo" src={logo} alt="Zoran Kuvač" />
              </Link>

              <div className="b-navbar-right">
                <Link className="navbar-link" to="/about">{Strings.about}</Link>
                <Link className="navbar-link" to="/contact">{Strings.contact}</Link>
              </div>
            </nav>
          </MediaQuery>
          <MediaQuery maxWidth={SM_MIN_W}>
            <div className="s-navbar-wrapper">
              <nav className={this.state.navbarClass}>
                <Link className="navbar-link" to="/gallery">{Strings.gallery}</Link>
                <Link className="navbar-link" to="/guitars">{Strings.guitars}</Link>
                <Link className="navbar-link" to="/about">{Strings.about}</Link>
                <Link className="navbar-link" to="/contact">{Strings.contact}</Link>
              </nav>
              <div className="s-visible">
                <Link className="logo-wrapper" to="/">
                  <img className="logo-s" src={logo} alt="Zoran Kuvač" />
                </Link>
                <button className={this.state.hamburgerClass} type="button" onClick={this.buttonClicked.bind(this)}>
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </div>
            </div>
          </MediaQuery>
        </div>
    );
  }
}

export default Navbar;